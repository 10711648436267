import FeatureGates from '@atlaskit/feature-gate-js-client';

export const useMarkdownPlusExperiment = () => {
	const markdownPlusEnabled = FeatureGates.getExperimentValue(
		'cc_quick_summary_md_plus_input',
		'markdownPlusEnabled',
		false,
	);

	return markdownPlusEnabled ? 'text/markdown-plus' : 'text/markdown';
};
